import React from 'react';
import type { Reducer } from 'react';
import type { TLocationName } from '@hooks';

type TState = {
  location: TLocationName;
};

type TActions = { type: 'CHANGE_LOCATION'; payload: TLocationName };

const initialState: TState = {
  location: 'Brisbane',
};

const reducer: Reducer<TState, TActions> = (state, action) => {
  switch (action.type) {
    case 'CHANGE_LOCATION':
      return {
        ...state,
        location: action.payload,
      };
  }
};

const SiteContext = React.createContext<{
  state: TState;
  dispatch: React.Dispatch<TActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const SiteProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <SiteContext.Provider value={{ state, dispatch }}>
      {children}
    </SiteContext.Provider>
  );
};

export { SiteProvider, SiteContext };
