import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { SiteProvider } from './src/context';
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

declare global {
  interface Window {
    _ctq: any[];
    _fdNumber: string;
    dataLayer: any[];
  }
}

const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <SiteProvider>{element}</SiteProvider>;
};

const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  if (window) {
    if (console) console.log('Firing FD tag to change numbers.');

    window._ctq = window._ctq || [];
    window._fdNumber = window._fdNumber || '';
    window._ctq.push(
      ['setSiteId', 'HU-33218043'],
      ['requires', ['main.phoneNumber']],
      [
        'replaceText',
        /1800[\s]+?890[\s]+?170/g,
        'main.phoneNumber',
        { format: 'n' },
      ],
      [
        'onNumberAvailable',
        function (num) {
          if (!document.querySelectorAll) return;
          window._fdNumber = num;
          const tels = document.querySelectorAll('a[href^="tel"]');

          for (let i = 0; i < tels.length; i++) {
            (tels[i] as HTMLAnchorElement).href = 'tel:' + num;
          }
        },
        'main.phoneNumber',
        { format: 'n' },
      ],
      ['trackPageView'],
    );

    const wca = document.createElement('script');
    wca.type = 'text/javascript';
    wca.async = !0;
    wca.src = 'https://web-call-analytics.com/wca.js';

    const s = document.getElementsByTagName('script')[0];
    if (s.parentNode) {
      s.parentNode.insertBefore(wca, s);
    }

    // Tag Manager
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // @ts-ignore
    gtag('js', new Date());
    // @ts-ignore
    gtag('config', 'G-QB1X3DXN5C');
  }
};

export { onInitialClientRender, wrapRootElement };
