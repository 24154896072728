exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-fast-facts-tsx": () => import("./../../../src/pages/fast-facts.tsx" /* webpackChunkName: "component---src-pages-fast-facts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-what-is-my-claim-worth-tsx": () => import("./../../../src/pages/what-is-my-claim-worth.tsx" /* webpackChunkName: "component---src-pages-what-is-my-claim-worth-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../../../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */)
}

